import React from 'react';
import MultiStepForm from 'mo-com/dist/Form/MultiStepForm';
import BraintreeDropin from "mo-com/dist/BraintreeDropin/BraintreeDropin";
import helper from 'components/utils/helper';
import Loading from 'components/common/Loading/Loading';
import { Heading, Container, FormWrapper, UpdateForm, Card, CardLabel, CardImage, CardEdit, SmallMessage, WarningMessage, BottomMessage, SubmitButton, UpdateLoading, SuccessIcon, MovemberLogo } from './SelfPaymentUpdate.style';


const EmptyCard = () => {
    return (
        <Card isExpired={true}>
            <CardImage alt="billing image" src="/assets/images/icons/visa.png" />
            <CardImage alt="billing image" src="/assets/images/icons/mastercard.png" />
            <CardLabel>Name on card</CardLabel>
            <div>**** ****</div>
            <CardLabel>Card Number</CardLabel>
            <div>{"XXXX XXXX XXXX XXXX"}</div>
            <CardLabel>Expiration</CardLabel>
            <div>{"MM/YYYY"} </div>
        </Card>
    );
};

const ExpiredPage = () => {
    return (
        <div>
            <p>Sorry, something’s gone wrong. This page has expired. Please check your email for the most up to date link or get in touch with your local Mo Team so we can sort it out.</p>
            <p>Thanks for being part of the hairy fold- you’re having an everlasting impact on the face of men’s health.</p>
            <EmptyCard/>
        </div>
    );
};

const ErrorPage = () => {
    return (
        <div>
            <p>Uh oh- something’s gone wrong. We’re having trouble processing this change. Get in touch with your local Mo Team on 1300476966 or info@movember.com and we’ll get it sorted out for you.</p>
            <p>Thanks for your support- you’re changing the face of men’s health, year round. Mo on!</p>
            <EmptyCard/>
        </div>
    );
};

const SelfPaymentUpdate = (props) => {
    const  [braintreeInstance, setBraintreeInstance] = React.useState(null);
    const  [braintreeValid, setBraintreeValid] = React.useState(false);
    const  [recurringPaymentData, setRecurringPaymentData] = React.useState(null);
    const  [config, setConfig] = React.useState(null);
    const  [currentStep, setCurrentStep] = React.useState(1);
    const  [isPaymentUpdating, setIsPaymentUpdating] = React.useState(false);
    const  [isLinkExpired, setIsLinkExpired] = React.useState(false);
    const  [isError, setIsError] = React.useState(false);

    const url = helper.getApiBaseUrl();
    const recurringPaymentId = helper.getRecurringPaymentId();
    const updateToken = helper.getUrlParam("token");

    React.useEffect(()=>{
        fetch(`${url}recurring-payment-form/${recurringPaymentId}?token=${updateToken}`,{
            method: 'GET',
        }).then(function(response) {
            return response.json();
        }).then(function(responseData){
            if (responseData.error) {
                // Handle expried link
                if(responseData.error === "Expired" && responseData.status === 400){
                    setIsLinkExpired(true);
                } else {
                    setIsError(true);
                    console.log(responseData.error);
                }
                setRecurringPaymentData({});
                setConfig({});
            } 
            if(responseData.success){
                setRecurringPaymentData(responseData.data);
                let respConfig = JSON.parse(JSON.stringify(responseData.data.config));
                // MWD-5370 Limit payment methods to card only
                if ('braintree' in respConfig) {
                    if ('paymentMethodList' in respConfig['braintree']) {
                        let myKeys = Object.keys(respConfig['braintree']['paymentMethodList']);
                        myKeys.forEach(key => {
                            if (key !== 'card') {
                                delete respConfig['braintree']['paymentMethodList'][key];
                            }
                        });
                    }
                }
                setConfig(respConfig)
            }
        }).catch(function(err) {
            console.log(err);
        });
        return () => {
        };
    }, []);

    function onBraintreeDropinCreate(instance, isRecurring) {
        setBraintreeInstance(instance);
    }

    function onBraintreeValidChange(valid){
        setBraintreeValid(valid);
    }

    function onGoToNextStep() {
        if (currentStep === 2) {
            if (!braintreeValid) {
                console.error("invalid braintree status");
                return false;
            } else {

                let paymentOptions = {};
                if (config.braintree && config.braintree.threeDSecure && config.braintree.threeDSecure.version === 2) {
                    paymentOptions.threeDSecure = {
                        amount: recurringPaymentData.amount,
                        version: 2,
                    };
                    paymentOptions.threeDSecure.email = recurringPaymentData.email;
                }

                braintreeInstance.requestPaymentMethod(paymentOptions, (requestPaymentMethodErr, payload) => {
                    if (requestPaymentMethodErr) {
                        // No payment method is available.
                        // An appropriate error will be shown in the UI.
                        console.error("BT dropin response error", requestPaymentMethodErr);
                        return false;
                    }
                    setIsPaymentUpdating(true);
                    fetch(`${url}recurring-payment/${recurringPaymentId}?token=${updateToken}`, {
                        method: 'PUT',
                        body: JSON.stringify({
                            credit_card: {
                                payment_method_nonce: payload.nonce
                            }
                        })
                    }).then(function (response) {
                        return response.json();
                    }).then(function (responseData) {
                        setIsPaymentUpdating(false);
                        setCurrentStep(currentStep + 1);
                    });
                });
            }
        } else {
            setCurrentStep(currentStep + 1);
        }
    }

    function onGoToPreviousStep() {
        setCurrentStep(currentStep - 1);
    }

    function getCardExpiringSoon(){
        if(recurringPaymentData && recurringPaymentData.billing && recurringPaymentData.billing.expirationDate){
            let expiryMonth = parseInt(recurringPaymentData.billing.expirationDate.substring(0,2)),
            expiryYear = parseInt(recurringPaymentData.billing.expirationDate.substring(3)),
            currentMonth = (new Date()).getMonth() + 1, // getMonth() return 0 to 11 for months in year
            currentYear  = (new Date()).getFullYear();
            return (currentYear === expiryYear) && (expiryMonth === currentMonth + 1);
        }
        return false;
    }

    function getPrivacyPolicy (){
        return (
            <BottomMessage><span level="small">Here is our <a href="https://au.movember.com/privacy" target="_blank" rel="noopener noreferrer">privacy policy</a> and our policy on your <a href="https://au.movember.com/privacy#creditCardData" target="_blank" rel="noopener noreferrer">credit card data</a></span></BottomMessage>
        );
    }

    return (
        <Container>
             { (!recurringPaymentData || !config) && (<Loading/>)}
         
             {recurringPaymentData && config && (
                 <FormWrapper>
                    <UpdateForm>
                        { isLinkExpired && <ExpiredPage/> }

                        { isError && <ErrorPage/> }

                        { !isLinkExpired && !isError && (
                            <MultiStepForm
                                currentStep={currentStep}
                                onGoToNextStep={onGoToNextStep}
                                onGoToPreviousStep={onGoToPreviousStep}
                                displayBackButtonOnStepHeader={false}
                                displayFooter={false}>
                                {/* STEP 1 - user info */}
                                <div className="multiStepForm--step">
                                    <Heading>Hey {recurringPaymentData.firstname}</Heading>
                                    <p>Looks like you need to update your payment details. Click below, it'll take less than a minute. Thanks for supporting Movember.</p>
                                    <Card isExpired={isLinkExpired}>
                                        <CardImage alt="billing image" src={recurringPaymentData.billing.imageUrl} />
                                        <CardLabel>Name on card</CardLabel>
                                        <div>{recurringPaymentData.billing.cardholderName || ( recurringPaymentData.firstname + " " + recurringPaymentData.lastname)}</div>
                                        <CardLabel>Card Number</CardLabel>
                                        <div>{"XXXX XXXX XXXX " + recurringPaymentData.billing.last4}</div>
                                        <CardLabel>Expiration</CardLabel>
                                        <div>{recurringPaymentData.billing.expirationDate} </div>
                                        <CardEdit onClick={onGoToNextStep}>Edit Card</CardEdit>
                                    </Card>
                                    { getCardExpiringSoon() && (
                                        <WarningMessage>Card expiring soon</WarningMessage>
                                    )}
                                    { getPrivacyPolicy() }
                                </div>

                                {/* STEP 2 - BT dropin */}
                                <div className="multiStepForm--step">
                                    <Heading>Edit Card</Heading>
                                    <p>Review and where required edit the  marked* fields in your card details below.</p>
                                    <SmallMessage>
                                        <span level="small">Your credit card information is secure <span className="fas fa-lock"></span></span>
                                    </SmallMessage>

                                    { !isPaymentUpdating &&(
                                        <BraintreeDropin
                                            countryCode='au'
                                            currencyCode='AUD'
                                            locale='en_AU'
                                            isRecurring={true}
                                            config={config}
                                            onDropinCreate={onBraintreeDropinCreate}
                                            onValidChange={onBraintreeValidChange}
                                        />
                                    )}
                                    { isPaymentUpdating && (
                                        <UpdateLoading> <div className="fas fa-sync-alt fa-spin"></div></UpdateLoading>
                                    )}
                                
                                    <SmallMessage>
                                        <span level="small">Recurring amount on the {recurringPaymentData.billing.billingDayOfMonth}th of each month*</span>
                                    </SmallMessage>
                                    <div>${recurringPaymentData.amount}</div>

                                    <SubmitButton type="button" onClick={onGoToNextStep} isValid={braintreeValid}>Submit Details</SubmitButton>
                                    { getPrivacyPolicy() }
                                </div>

                                {/* STEP 3 - thank you page */}
                                <div className="multiStepForm--step">
                                    <Heading>Success!</Heading>
                                    <p>Thank you- your payment details have now been updated. We’ve made that change and will send you a confirmation email for your records.</p>
                                    <p>Your generosity is making a massive difference across the critical areas of prostate cancer, testicular cancer, mental health and suicide prevention. You're having an everlasting impact on the face of men's health- cheers to you.</p>
                                    <SuccessIcon></SuccessIcon>
                                    <MovemberLogo>
                                        <img src="/assets/images/global/movember-logo-horizontally.svg" alt=""/>
                                        <p>While you are here, would you like to <a href="https://au.movember.com/about/cause">see the impact your money is having?</a></p>
                                    </MovemberLogo>
                                    <SmallMessage>
                                        <span level="small">Note: It may take an hour to see your updated info and payment details</span>
                                    </SmallMessage>
                                </div>
                            </MultiStepForm>
                        )}
                        
                    </UpdateForm>
                 </FormWrapper>
            )}

        </Container>
     );
}
 
export default SelfPaymentUpdate;
