import styled from "styled-components";

const Heading = styled.div`
    font-family: Overpass;
    font-weight: 700;
    font-size: 23px;
    
    @media screen and (min-width: 1366px){
        font-size: 31px;
    }

    @media screen and (min-width: 1920px){
        font-size: 44px;
    }
`;

const Container = styled.div`
    max-height: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FormWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const UpdateForm = styled.div`
    max-width: 620px;
    padding: 45px 15px;
`;

const Card = styled.div`
    position: relative;
    border-radius: 4px;
    box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.1);
    padding: 20px 30px;
    margin: 4px;

    @media screen and (min-width: 480px){
        width: 80%;
    }

    ${(props) => props.isExpired && `
        opacity: .5;
        cursor: not-allowed;
    `}

`;

const CardLabel = styled.label`
    font-size: 16px;
    opacity: 0.5;
`;

const CardImage = styled.img`
    position: absolute;
    right: 30px;
`;

const CardEdit = styled.a`
    position: absolute;
    right: 30px;
    bottom: 20px;
    text-decoration: underline;
    color: blue;
    cursor: pointer;
`;

const SmallMessage = styled.div`
    opacity: 0.6;
    span.fas {
        margin-left: 8px;
    }
`;

const WarningMessage = styled.div`
    color: #ff9a00;
    margin-top: 16px;
`;

const BottomMessage = styled.div`
    margin-top: 16px;
`;

const SubmitButton = styled.button`
    width: 140px;
    height: 34px;
    border: solid 1px #001eff;
    margin-top: 12px;
    font-family: Overpass;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    outline: none;
    background-color: #ffffff;
    color: #001eff;
    opacity: 0.6;
    cursor: not-allowed;
    
    ${(props) => props.isValid && `
        background-color: #001eff;
        color: #ffffff;
        opacity: 1;
        cursor: pointer;
    `}

    @media screen and (min-width: 768px){
        width: 180px;
        font-size: 16px;
        height: 48px;
    }
`;

const UpdateLoading = styled.div`
    font-size: 50px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SuccessIcon = styled.div`
    height: 250px;
    background-image: url('/assets/images/icons/success-circle.svg');
    background-size: 88px;
    background-repeat: no-repeat;
    background-position: center;
`;

const MovemberLogo = styled.div`
    padding: 20px 0 ;
    border-top: solid 1px rgba(0, 0, 0, .1);
    border-bottom: solid 1px rgba(0, 0, 0, .1);
    margin-bottom: 20px;
    p {
        margin: 0;
    }
`;

export { Heading, Container, FormWrapper, UpdateForm, Card, CardLabel, CardImage, CardEdit, SmallMessage, WarningMessage, BottomMessage, SubmitButton, UpdateLoading, SuccessIcon, MovemberLogo };