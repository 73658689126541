import React from 'react';
import SelfPaymentUpdate from './components/SelfPaymentUpdate/SelfPaymentUpdate.jsx';

function App() {
  return (
    <SelfPaymentUpdate></SelfPaymentUpdate>
  );
}

export default App;
