import React from 'react';
import styled from 'styled-components';

const LoadingOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100%;
    width: 100%;
    background-color: black;
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#99000000',endColorstr='#99000000')";
    zoom: 1;
    -webkit-transition: opacity 0.3s ease, visibility 0.3s ease;
    -o-transition: opacity 0.3s ease, visibility 0.3s ease;
    transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const LoadingIcon = styled.span`
    color: #FFF;
    display: inline;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -4px 0 0 -4px;
    font-size: 50px;
`;

const Loading = () => {
    return ( 
        <LoadingOverlay>
            <LoadingIcon className="fas fa-sync-alt fa-spin"></LoadingIcon>
        </LoadingOverlay>
     );
}
 
export default Loading;