import { useState, useEffect } from 'react';

const helper = (() => {

    function useWindowWidth() {
        const [ width, setWidth ] = useState(window.innerWidth);
        useEffect(()=>{
            const handleResize = () => setWidth(window.innerWidth);
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        });
        return width;
    }

    function getEnv() {
        const hostname = window.location.hostname;
        if (hostname === 'selfservice.movember.com') {
            return "prod";
        } else if (hostname === 'localhost' || hostname === 'dev.selfservice.movember.com') {
            return 'dev';
        } else {
            return 'uat';
        }
    }

    function getApiBaseUrl() {
        const env = getEnv();
        console.log(env);
        let urlComponent = "";
        if (env !== "prod") {
            urlComponent = "."+env;
        }

        return `https://api${urlComponent}.movember.com/v18/`;
    }

    function getRecurringPaymentId() {
        let path = window.location.pathname;
        return path.substring(path.indexOf('self-update/') + 12); // 12 is number of characters in "self-update/"
    }

    /**
    *
    * getUrlParam
    *
    * @param paramId the parameter name
    * @param additionalAllowedCharacters additional characters that we consider valid when looking for a parameter value in the URL.
    *                                    As a general rule, only letters, numbers, '_', '-', '+', '%' and '.' are valid.
    *                                    If we want to consider any other valid characters (e.g. '@'), we have to include them here.
    */
   function getUrlParam(paramId, additionalAllowedCharacters) {
       var _chars = additionalAllowedCharacters || '';

       // Get the parameter string; note: for security reasons only certain characters are matched
       var paramString = window.location.href.match( new RegExp(paramId + '=[-a-zA-Z0-9' + _chars + '_+%.]*', 'g') );   // '_', '+', '%' and '.' characters added
       // Return the result:
       return ( paramString ? paramString[0].replace(paramId+'=','') : null );
   }
    
    return {
        useWindowWidth: useWindowWidth,
        getApiBaseUrl: getApiBaseUrl,
        getUrlParam : getUrlParam,
        getRecurringPaymentId : getRecurringPaymentId
    };
})();
 
export default helper;
